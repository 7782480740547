<template>
  <div>
    <br />
    <p>Congratulations for completing your task!</p>

    <img src="../assets/bursting.gif" />
    <br />
    <br />
    <van-button type="info" v-on:click="goBack">Close</van-button>
  </div>
</template>

<script>
export default {
  name: "TaskCompleted",
  props: {
    task: Object
  },

  methods: {
    goBack() {
      this.$router.push({
        name: "home-page"
      });
    }
  }
};
</script>

<style scoped></style>
