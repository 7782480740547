<template>
  <component :is="journalFactory" :task="task"></component>
</template>

<script>
export default {
  name: "TaskJournal",
  props: {
    task: Object
  },
  methods: {
    journalFactory() {
      let type = this.task.type;
      type = type[0].toUpperCase() + type.slice(1).toLowerCase();
      return import("@/components/Journal/" + type + "TaskJournal");
    }
  }
};
</script>

<style scoped></style>
