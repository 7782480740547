<template>
  <div class="task_journal">
    <TaskJournal :task="taskToJournal" />

    <TaskCompleted v-if="taskJournaled" :task="journaledStep" />
  </div>
</template>

<script>
import TaskJournal from "@/components/TaskJournal.vue";
import { TaskProvider } from "@/providers";
import TaskCompleted from "@/components/TaskCompleted.vue";

export default {
  name: "TaskJournalPage",
  props: {
    taskId: {
      type: [String, Number],
      required: true
    },
    task: {
      type: Object
    }
  },

  components: {
    TaskJournal,
    TaskCompleted
  },

  data() {
    return {
      taskToJournal: {},
      journaledStep: {},
      taskLoaded: false,
      taskJournaled: false,
      continueJournaling: false
    };
  },

  watch: {
    taskToJournal: function(newTask) {
      if (typeof newTask !== "undefined") {
        if (newTask.status === "completed") {
          this.taskJournaled = true;
        }
      }
    }
  },

  created() {
    this.loadTaskIfNeeded();

    window.Event.listen("TaskJournalingCompleted", this.journalingCompleted);
  },

  methods: {
    async loadTask() {
      const self = this;

      TaskProvider.getTask(this.taskId).then(task => {
        self.taskToJournal = task;
        self.taskLoaded = true;
      });
    },

    loadTaskIfNeeded() {
      if (typeof this.task !== "undefined") {
        this.taskToJournal = this.task;
        this.taskLoaded = true;
      }

      this.loadTask();
    },

    journalingCompleted(task) {
      if (task.id === this.taskId) {
        this.journaledStep = task;
        this.taskJournaled = true;
      }
    },

    onClickContinueToJournal(value) {
      if (value.id) {
        this.continueJournaling = true;
      }
    }
  }
};
</script>

<style scoped></style>
